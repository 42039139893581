import { useDispatch, useSelector } from "react-redux";
import { LayoutDefault } from "@components/layout/Default";
import { getUser } from "@store/slices/userSlice";
import { useLocation, Navigate, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { getAllBusiness } from "@services/business";
import { Button } from "@components/atomic/Button/Button";
import { setUserState } from "@store/slices/userSlice";

export interface business {
  nome: string;
  token: string;
}

export default function Admin() {
  const { user } = useSelector(getUser);
  const location = useLocation();
  const [businessList, setBusinessList] = useState<business[]>([]);
  const [selectedBusiness, setSelectedBusiness] =
    useState<business | null>(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const getBusiness = async () => {
      try {
        console.log();
        const allBusiness = await getAllBusiness(user.adminToken);
        setBusinessList(allBusiness.data);
      } catch (e) {
        console.log("Erro ao obter empresas.");
      }
    };

    getBusiness();
  }, []);

  const handleBusinessSelect = (e: React.FormEvent) => {
    const target = e.target as typeof e.target & {
      value: number;
    };

    setSelectedBusiness(businessList[target.value]);
  };

  const handleSelectBusiness = () => {
    dispatch(
      setUserState({
        admin: "true",
        adminToken: user.adminToken,
        client: selectedBusiness?.nome,
        help: false,
        token: selectedBusiness?.token,
      })
    );

    navigate("/home");
  };

  return (
    <>
      {user.admin !== "true" ? (
        <Navigate to="/login" state={{ from: location }} replace />
      ) : (
        <LayoutDefault>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0">Selecione a empresa:</h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-12">
                        <select
                          className="form-select form-select-lg mb-3"
                          aria-label="Default select example"
                          disabled={businessList.length === 0}
                          onChange={handleBusinessSelect}
                        >
                          <option>Selecione...</option>
                          {businessList.map((obj, key) => (
                            <option key={obj.token} value={key}>
                              {obj.nome}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <Button
                          disabled={selectedBusiness === null}
                          onClick={handleSelectBusiness}
                        >
                          Selecionar {selectedBusiness && selectedBusiness.nome}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </LayoutDefault>
      )}
    </>
  );
}
