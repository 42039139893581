import { getRequest } from "@utils/axiosInstance";

export function getTokens(token: string) {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  return getRequest(`tokens`, config);
}

export function resendValidation(token: string) {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  return getRequest(`resendvalidacao`, config);
}
