import { postRequest } from "@utils/axiosInstance";

export function getAllBusiness(token: string) {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  return postRequest("clients/get", {}, config);
}

export function getBusinessTransactions(
  token: string,
  period: "day" | "month",
  payload: object
) {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  return postRequest(`transactions/${period}`, payload, config);
}

export function getBusinessProfile(token: string) {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  return postRequest(`client/get`, {}, config);
}

export function businessChangePassword(token: string, newPassword: string) {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  return postRequest(`client/change_password`, { senha: newPassword }, config);
}
