import { postRequest } from "@utils/axiosInstance";

export function getUsability(type = "env", token: string) {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  return postRequest(`rel/ope/group/range/usability/${type}`, {}, config);
}

export function searchWorstIds(token: string) {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  return postRequest("rel/list/count/worst/ids", {}, config);
}
