import { LayoutDefault } from "@components/layout/Default";
import { useSelector } from "react-redux";
import { getUser } from "@store/slices/userSlice";
import { useEffect, useState } from "react";
import { Button } from "@components/atomic/Button/Button";
import Flatpickr from "react-flatpickr";
import { Portuguese } from "flatpickr/dist/l10n/pt";
import { transactionsWithFilters } from "@services/transactions";
import { Grid, _ } from "gridjs-react";
import { ptBR } from "gridjs/l10n";
import { Alert } from "@components/atomic/Alert/Alert";

export default function Transactions() {
  const { user } = useSelector(getUser);

  const [transactions, setTransactions] = useState([]);

  const [filterPayload, setFilterPayload] = useState({
    de: new Date().toISOString().split("T")[0],
    ate: new Date().toISOString().split("T")[0],
    cdPessoa: "",
    checkCadastro: "S",
    checkVerificacao: "S",
    checkIdentificacao: "S",
    checkDesativacao: "S",
    status: "0",
  });

  const handleSearchTransactions = async () => {
    try {
      const { data: filteredTransactions } = await transactionsWithFilters(
        user.token,
        filterPayload
      );
      setTransactions(filteredTransactions);
    } catch (error) {
      console.log("Erro ao buscar transações");
    }
  };

  useEffect(() => {
    handleSearchTransactions();
  }, []);

  return (
    <>
      <LayoutDefault>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0">Transações</h4>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-header">
                  <strong>Buscar transações</strong>
                </div>
                <div className="card-body">
                  <div className="row mb-0 mb-md-4">
                    <div className="col-md-3 mb-3 mb-md-0">
                      <label className="form-label">
                        Selecione a data inicial:
                      </label>
                      <Flatpickr
                        className="form-control"
                        options={{
                          dateFormat: "d M, Y",
                          defaultDate: [new Date()],
                          locale: Portuguese,
                          maxDate: new Date(),
                        }}
                        onChange={(date: any) => {
                          setFilterPayload({
                            ...filterPayload,
                            de: new Date(date).toISOString().split("T")[0],
                          });
                        }}
                      />
                    </div>
                    <div className="col-md-3 mb-3 mb-md-0">
                      <label className="form-label">
                        Selecione a data final:
                      </label>
                      <Flatpickr
                        className="form-control"
                        options={{
                          dateFormat: "d M, Y",
                          defaultDate: [new Date()],
                          locale: Portuguese,
                          maxDate: new Date(),
                        }}
                        onChange={(date: any) => {
                          setFilterPayload({
                            ...filterPayload,
                            ate: new Date(date).toISOString().split("T")[0],
                          });
                        }}
                      />
                    </div>
                    <div className="col-md-3 mb-3 mb-md-0">
                      <label className="form-label">
                        Identificador / ID pessoa:
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={filterPayload.cdPessoa}
                        maxLength={20}
                        onChange={(e) =>
                          setFilterPayload({
                            ...filterPayload,
                            cdPessoa: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="col-md-3 mb-3 mb-md-0">
                      <label className="form-label">Resultado / Status:</label>
                      <select
                        className="form-select"
                        defaultValue={filterPayload.status}
                        onChange={(e) => {
                          setFilterPayload({
                            ...filterPayload,
                            status: e.target.value,
                          });
                        }}
                      >
                        <option value="0">Todos</option>
                        <option value="200">OK</option>
                        <option value="602">
                          Não foi possível criar template
                        </option>
                        <option value="603">Biometria não encontrada</option>
                        <option value="605">Foto falsa detectada</option>
                        <option value="607">Foto já cadastrada</option>
                      </select>
                    </div>
                  </div>
                  <div className="row mb-0 mb-md-4">
                    <div className="col-lg-12 mb-3 mb-md-0">
                      <div className="form-check form-check-secondary form-check-inline">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="checkCadastro"
                          defaultChecked={filterPayload.checkCadastro === "S"}
                          onChange={(e: any) =>
                            setFilterPayload({
                              ...filterPayload,
                              checkCadastro: e.target.checked ? "S" : "N",
                            })
                          }
                        />
                        <label
                          htmlFor="checkCadastro"
                          className="form-check-label"
                        >
                          Cadastro
                        </label>
                      </div>
                      <div className="form-check form-check-secondary form-check-inline">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="checkVerificacao"
                          defaultChecked={
                            filterPayload.checkVerificacao === "S"
                          }
                          onChange={(e: any) =>
                            setFilterPayload({
                              ...filterPayload,
                              checkVerificacao: e.target.checked ? "S" : "N",
                            })
                          }
                        />
                        <label
                          htmlFor="checkVerificacao"
                          className="form-check-label"
                        >
                          Verificação
                        </label>
                      </div>
                      <div className="form-check form-check-secondary form-check-inline">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="checkDesativacao"
                          defaultChecked={
                            filterPayload.checkDesativacao === "S"
                          }
                          onChange={(e: any) =>
                            setFilterPayload({
                              ...filterPayload,
                              checkDesativacao: e.target.checked ? "S" : "N",
                            })
                          }
                        />
                        <label
                          htmlFor="checkDesativacao"
                          className="form-check-label"
                        >
                          Desativação
                        </label>
                      </div>
                      <div className="form-check form-check-secondary form-check-inline">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="checkIdentificacao"
                          defaultChecked={
                            filterPayload.checkIdentificacao === "S"
                          }
                          onChange={(e: any) =>
                            setFilterPayload({
                              ...filterPayload,
                              checkIdentificacao: e.target.checked ? "S" : "N",
                            })
                          }
                        />
                        <label
                          htmlFor="checkIdentificacao"
                          className="form-check-label"
                        >
                          Identificação
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 mb-3 mb-md-0 text-md-left">
                    <Button onClick={handleSearchTransactions}>Buscar</Button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-header">Resultados</div>
                <div className="card-body">
                  {transactions.length === 0 && (
                    <Alert color="info">
                      Nenhum resultado para os filtros acima.
                    </Alert>
                  )}
                  {transactions.length > 0 && (
                    <div>
                      <Grid
                        data={transactions}
                        columns={[
                          {
                            name: "ID",
                          },
                          {
                            name: "TIPO",
                          },
                          {
                            id: "date_insert",
                            name: "DATA",
                          },
                          {
                            id: "status",
                            name: "RESULTADO/STATUS",
                          },
                          {
                            id: "id_pessoa_cliente",
                            name: "ID Pessoa",
                          },
                        ]}
                        search={true}
                        sort={true}
                        pagination={{ enabled: true, limit: 10 }}
                        language={ptBR}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutDefault>
    </>
  );
}
