import { LayoutDefault } from "@components/layout/Default";
import { useSelector } from "react-redux";
import { getUser } from "@store/slices/userSlice";
import { Button } from "@components/atomic/Button/Button";
import { FormEvent, useEffect, useState } from "react";
import { businessChangePassword, getBusinessProfile } from "@services/business";
import { Alert } from "@components/atomic/Alert/Alert";
import { resendValidation } from "@services/tokens";

export default function Profile() {
  const { user } = useSelector(getUser);

  const [userData, setUserData] = useState({
    nome: "",
    cnpj: "",
    cadastro: "",
    email: "",
    dias_trial: null,
    emailValid: true,
  });

  const [newPassword, setNewPassword] = useState("");
  const [newPaswordConfirm, setNewPasswordConfirm] = useState("");
  const [saveIsEnabled, setSaveIsEnabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [resentEmailMessage, setResentEmailMessage] = useState("");

  useEffect(() => {
    const getBusinessProfileData = async () => {
      try {
        const { data: profileData } = await getBusinessProfile(user.token);
        setUserData(profileData);
      } catch (error) {
        console.log("Erro ao obter dados do perfil", error);
      }
    };

    getBusinessProfileData();
  }, []);

  useEffect(() => {
    if (newPassword.trim() !== "" && newPaswordConfirm.trim() !== "") {
      if (newPassword !== newPaswordConfirm) {
        setErrorMessage(
          "A nova senha e a confirmação de senha não são iguais."
        );
        setSaveIsEnabled(false);
        return;
      }

      if (newPassword.trim().length < 5) {
        setErrorMessage("A senha deve conter 5 caracteres no mínimo!");
        setSaveIsEnabled(false);
        return;
      }

      setErrorMessage("");
      setSaveIsEnabled(true);
    } else {
      setErrorMessage("");
      setSaveIsEnabled(false);
    }
  }, [newPassword, newPaswordConfirm]);

  const handleChangePasswordFormSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (!saveIsEnabled || errorMessage !== "") {
      setErrorMessage("Preencha os campos de alteração de senha corretamente");
      return;
    }

    try {
      const retorno = businessChangePassword(user.token, newPassword);
      console.log(retorno);
    } catch (error) {
      console.log("Erro desconhecido", error);
    }
  };

  const handleResendVerification = async () => {
    setResentEmailMessage("Aguarde...");
    try {
      await resendValidation(user.token);
      setResentEmailMessage("E-mail de ativação foi enviado.");
    } catch (error) {
      console.log("Erro ao reenviar E-mail:", error);
      setResentEmailMessage(
        "Erro ao reenviar e-mail, tente novamente em breve."
      );
    }
  };

  return (
    <>
      <LayoutDefault>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0">Editar Perfil</h4>
              </div>
            </div>
          </div>
          <div className="row">
            <form onSubmit={handleChangePasswordFormSubmit}>
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row mb-0 mb-md-4">
                      <div className="col-md-4 mb-3 mb-md-0">
                        <label className="form-label">Nome:</label>
                        <input
                          type="text"
                          className="form-control"
                          disabled={true}
                          defaultValue={userData.nome}
                        />
                      </div>
                      <div className="col-md-4 mb-3 mb-md-0">
                        <label className="form-label">CNPJ:</label>
                        <input
                          type="text"
                          className="form-control"
                          disabled={true}
                          defaultValue={userData.cnpj}
                        />
                      </div>
                      <div className="col-md-4 mb-3 mb-md-0">
                        <label className="form-label">Data de cadastro:</label>
                        <input
                          type="text"
                          className="form-control"
                          disabled={true}
                          defaultValue={userData.cadastro}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4 mb-3 mb-md-0">
                        <label className="form-label">Email:</label>
                        <input
                          type="text"
                          className="form-control"
                          disabled={true}
                          defaultValue={userData.email}
                        />
                      </div>
                      <div className="col-md-4 mb-3 mb-md-0">
                        <label className="form-label">Nova senha:</label>
                        <input
                          type="password"
                          className="form-control"
                          value={newPassword}
                          onChange={(e) => setNewPassword(e.target.value)}
                          maxLength={40}
                        />
                      </div>
                      <div className="col-md-4">
                        <label className="form-label">
                          Confirme a nova senha:
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          value={newPaswordConfirm}
                          onChange={(e) =>
                            setNewPasswordConfirm(e.target.value)
                          }
                          maxLength={40}
                        />
                      </div>
                    </div>
                    {errorMessage !== "" && (
                      <div className="row mt-4">
                        <div className="col-lg-12">
                          <Alert color="danger">{errorMessage}</Alert>
                        </div>
                      </div>
                    )}
                    <div className="row mt-4">
                      <div className="col-lg-12">
                        <Button
                          size="lg"
                          type="submit"
                          disabled={!saveIsEnabled}
                        >
                          Salvar
                        </Button>
                        {`   `}
                        {userData.dias_trial && (
                          <span className="badge badge-label bg-info">
                            <i className="mdi mdi-circle-medium"></i>
                            Você possui {userData.dias_trial} dia
                            {Number(userData.dias_trial) > 1 && "s"} de
                            avaliação.
                          </span>
                        )}
                      </div>
                    </div>
                    {userData.emailValid === false && (
                      <div className="row mt-4">
                        <div className="col-lg-12">
                          <hr />
                          <p>Atenção: Seu e-mail ainda não foi verificado.</p>
                          <Button size="lg" onClick={handleResendVerification}>
                            Reenviar e-mail de verificação
                          </Button>
                          {`   `}
                          {resentEmailMessage !== "" && (
                            <span className="badge badge-label bg-info">
                              <i className="mdi mdi-circle-medium"></i>
                              {resentEmailMessage}
                            </span>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </LayoutDefault>
    </>
  );
}
