import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import logo from "@assets/images/logo-light.png";
import { Alert } from "@components/index";
import { doLogin } from "@services/login";
import { setUserState, restartUserState } from "@store/slices/userSlice";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";

export default function Login({ logout = false }) {
  const [loginErrorMessage, setLoginErrorMessage] = useState("");
  const [requestingLogin, setRequestingLogin] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [user, setUser] = useState("");
  const [pass, setPass] = useState("");
  const [isTrial, setIsTrial] = useState(false);
  const [cookies] = useCookies();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (logout === true) {
      dispatch(restartUserState());
      setSuccessMessage("Até breve!");
    }

    if (cookies.pwd_facialid && cookies.user_facialid) {
      setUser(cookies.user_facialid);
      setPass(cookies.pwd_facialid);
      setIsTrial(true);
    }
  }, []);

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    setRequestingLogin(true);

    const target = e.target as typeof e.target & {
      login: { value: string };
      senha: { value: string };
    };

    try {
      const responseFromApi = await doLogin(
        target.login.value,
        target.senha.value
      );

      const userData = responseFromApi.data.data;

      if (userData.erro) {
        setLoginErrorMessage(userData.erro);
      } else {
        setLoginErrorMessage("");
        dispatch(setUserState({ adminToken: userData.admin === "true" ? userData.token : null, ...userData }));
        navigate("/home");
      }
    } catch (e) {
      console.log("Erro inesperado.", e);
      setLoginErrorMessage("Erro inesperado. Entre em contato com suporte.");
    } finally {
      setRequestingLogin(false);
    }
  };

  return (
    <>
      <div className="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100">
        <div className="bg-overlay"></div>
        <div className="auth-page-content overflow-hidden pt-lg-5">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="card overflow-hidden">
                  <div className="row g-0">
                    <div className="col-lg-6">
                      <div className="p-lg-5 p-4 auth-one-bg h-100">
                        <div className="bg-overlay"></div>
                        <div className="position-relative h-100 d-flex flex-column">
                          <div className="mb-4">
                            <a href="index.html" className="d-block">
                              <img
                                src={logo}
                                alt="ComputerID Logo"
                                width={128}
                                height={83}
                              />
                            </a>
                          </div>
                          <div className="mt-auto">
                            <div className="mb-3">
                              <i className="ri-double-quotes-l display-4 text-success"></i>
                            </div>

                            <div
                              id="qoutescarouselIndicators"
                              className="carousel slide"
                              data-bs-ride="carousel"
                            >
                              <div className="carousel-inner text-center text-white pb-5">
                                <div className="carousel-item active">
                                  <p className="fs-17 fst-italic">
                                    Seja bem-vindo ao nosso painel
                                    administrativo.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="p-lg-5 p-4">
                        <div>
                          <h5 className="text-primary">Acesso ao sistema</h5>
                          <p className="text-muted">
                            Preencha os campos abaixo.
                          </p>
                          {successMessage !== "" && (
                            <Alert color="success">{successMessage}</Alert>
                          )}
                        </div>

                        {loginErrorMessage && (
                          <Alert color="danger">{loginErrorMessage}</Alert>
                        )}

                        {isTrial && (
                          <Alert color="success">
                            <div className="mb-1">
                              Bem-vindo ao modo trial. Seu cadastro foi efetuado
                              com sucesso!
                            </div>
                            <div>
                              Clique em Entrar para conhecer nosso sistema.
                            </div>
                          </Alert>
                        )}

                        <div className="mt-4">
                          <form onSubmit={handleLogin}>
                            <div className="mb-3">
                              <label className="form-label">E-mail:</label>
                              <input
                                type="email"
                                className="form-control"
                                placeholder="Informe seu e-mail"
                                name="login"
                                defaultValue={user}
                                onChange={(e) => setUser(e.target.value)}
                                disabled={isTrial}
                              />
                            </div>

                            <div className="mb-3">
                              <label className="form-label">Senha</label>
                              <div className="position-relative auth-pass-inputgroup mb-3">
                                <input
                                  type="password"
                                  className="form-control pe-5 password-input"
                                  placeholder="Enter password"
                                  name="senha"
                                  defaultValue={pass}
                                  onChange={(e) => setPass(e.target.value)}
                                  disabled={isTrial}
                                />
                                <button
                                  className="btn btn-link position-absolute end-0 top-0 text-decoration-none shadow-none text-muted password-addon"
                                  type="button"
                                  id="password-addon"
                                >
                                  <i className="ri-eye-fill align-middle"></i>
                                </button>
                              </div>
                            </div>

                            <div className="mt-4">
                              <button
                                className="btn btn-success w-100"
                                type="submit"
                                disabled={requestingLogin}
                              >
                                Entrar
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <footer className="footer">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="text-center">
                  <p className="mb-0">
                    &copy; Desenvolvido por ComputerID{" "}
                    <i className="mdi mdi-heart text-danger"></i>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
}
