import { getRequest, postRequest } from "@utils/axiosInstance";

export function statusSystem(token: string) {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  return getRequest("v2/isok", config);
}

export function listAllUsers(token: string) {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  return getRequest("v2/list", config);
}

export function registerUser(
  token: string,
  withLiveness = false,
  fotob64: string,
  idPessoa: string,
  data = {}
) {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const url = withLiveness ? "v2/liveness/register" : "v2/register";

  return postRequest(url, { fotob64, idPessoa, data }, config);
}

export function verifyUser(
  token: string,
  withLiveness = false,
  fotob64: string,
  idPessoa: string,
  data = {}
) {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const url = withLiveness ? "v2/liveness/verify" : "v2/verify";

  return postRequest(url, { fotob64, idPessoa, data }, config);
}

export function identifyUser(
  token: string,
  withLiveness = false,
  fotob64: string
) {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const url = withLiveness ? "v2/liveness/identify" : "v2/identify";

  return postRequest(url, config, { fotob64 });
}

export function deactivateUser(token: string, idPessoa: string) {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  return postRequest("v2/deactivate", { idPessoa }, config);
}
