import { createSlice } from "@reduxjs/toolkit";

export interface UserProps {
  user: {
    admin: boolean;
    adminToken: string | null;
    client: string | null;
    help: boolean;
    token: string | null;
  };
}

const initialState: UserProps = {
  user: {
    admin: false,
    adminToken: null,
    client: null,
    help: false,
    token: null,
  },
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserState(state, action) {
      state.user = action.payload;
    },
    restartUserState(state) {
      state.user = initialState.user;
    },
  },
});

export const { setUserState, restartUserState } = userSlice.actions;
export const getUser = (state: any) => state.user;
export default userSlice.reducer;
