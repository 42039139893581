import { useSelector } from "react-redux";
import { getUser } from "@store/slices/userSlice";
import { useNavigate, Link, useLocation } from "react-router-dom";

import logo from "@assets/images/logo-light.png";
import logoSm from "@assets/images/logo-sm.png";

export function LayoutDefault({ children }: { children: React.ReactNode }) {
  const { user } = useSelector(getUser);
  const navigate = useNavigate();
  const location = useLocation();

  /* TODO: refactor using store */
  const handleMenuChange = () => {
    const html = document.getElementsByTagName("html")[0];
    if (html.getAttribute("data-sidebar-size") === "lg") {
      html.setAttribute("data-sidebar-size", "sm");
    } else {
      html.setAttribute("data-sidebar-size", "lg");
    }
  };

  const handleMenuMobile = () => {
    const html = document.getElementsByTagName("html")[0];
    const body = document.getElementsByTagName("body")[0];

    if (body.classList.contains("vertical-sidebar-enable")) {
      body.classList.remove("vertical-sidebar-enable");
    } else {
      body.classList.add("vertical-sidebar-enable");
      html.setAttribute("data-sidebar-size", "lg");
    }
  };

  const handleChangeBusiness = () => {
    navigate("/admin");
  };

  const handleTheme = () => {
    const themeElement = document.getElementsByTagName('html')[0];
    const actualTheme = themeElement.getAttribute('data-layout-mode');
    themeElement.setAttribute('data-layout-mode', actualTheme === 'light' ? 'dark' : 'light');
  }

  return (
    <div>
      <header id="page-topbar" className="">
        <div className="layout-width">
          <div className="navbar-header">
            <div className="d-flex">
              <button
                type="button"
                className="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger shadow-none d-none d-md-inline"
                id="topnav-hamburger-icon"
                onClick={handleMenuChange}
                title="Expandir/Contrair menu"
              >
                <span className="hamburger-icon">
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
              </button>

              <button
                type="button"
                className="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger shadow-none d-md-none"
                id="topnav-hamburger-icon"
                onClick={handleMenuMobile}
                title="Abrir menu"
              >
                <span className="hamburger-icon">
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
              </button>
            </div>
            <div className="d-flex align-items-center">
              <div className="ms-1 header-item d-none d-sm-flex">
                  <button 
                    type="button" 
                    className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle light-dark-mode shadow-none"
                    onClick={handleTheme}
                  >
                      <i className="bx bx-moon fs-22"></i>
                  </button>
              </div>
              {user.admin && (
                <div className="ms-1 header-item d-none d-sm-flex">
                  <button
                    type="button"
                    className="btn btn-icon btn-topbar btn-ghost-secondary shadow-none"
                    title="Trocar empresa"
                    onClick={handleChangeBusiness}
                  >
                    <i className="bx bxs-business fs-22"></i>
                  </button>
                </div>
              )}
              <div className="ms-sm-3 header-item dropdown topbar-user">
                <Link to="/perfil" type="button" className="btn shadow-none">
                  <span className="d-flex align-items-center">
                    <span className="text-start ms-xl-2">
                      <span className="d-block ms-1 fs-12 text-muted user-name-sub-text">
                        Bem-vindo (a)
                      </span>
                      <span className="d-inline-block ms-1 fw-medium user-name-text">
                        {user.client}
                      </span>
                    </span>
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div className="app-menu navbar-menu">
        <div className="navbar-brand-box">
          <a href="index.html" className="logo logo-light">
            <span className="logo-sm">
              <img src={logoSm} alt="ComputerID Logo" width={60} height={60} />
            </span>
            <span className="logo-lg">
              <img src={logo} alt="ComputerID Logo" width={128} height={83} style={{marginTop: "15px"}} />
            </span>
          </a>
          <button
            type="button"
            className="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover"
            id="vertical-hover"
          >
            <i className="ri-record-circle-line"></i>
          </button>
        </div>
        <div className="h-100">
          <ul className="navbar-nav">
            <li className="menu-title" style={{textAlign: "center"}}>
              <span data-key="t-menu">GESTÃO FACIAL ID</span>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link menu-link${
                  location.pathname === "/home" && " active"
                }`}
                to="/home"
                role="button"
              >
                <i className="mdi mdi-home"></i>{" "}
                <span data-key="t-dashboards" className="intro-step-dashboards">
                  Dashboards
                </span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link menu-link${
                  location.pathname === "/manageusers" && " active"
                }`}
                to="/manageusers"
                role="button"
              >
                <i className="mdi mdi-chart-line"></i>{" "}
                <span data-key="t-dashboards" className="intro-step-usuarios">
                  Gestão de Usuários
                </span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link menu-link${
                  location.pathname === "/documentacao" && " active"
                }`}
                to="/documentacao"
                role="button"
              >
                <i className="mdi mdi-file-document-multiple"></i>{" "}
                <span
                  data-key="t-dashboards"
                  className="intro-step-documentacao"
                >
                  Documentação
                </span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link menu-link${
                  location.pathname === "/transacoes" && " active"
                }`}
                to="/transacoes"
                role="button"
              >
                <i className="mdi mdi-transfer"></i>{" "}
                <span data-key="t-dashboards" className="intro-step-transacoes">
                  Transações
                </span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link menu-link${
                  location.pathname === "/estatisticas" && " active"
                }`}
                to="/estatisticas"
                role="button"
              >
                <i className="mdi mdi-chart-line"></i>{" "}
                <span
                  data-key="t-dashboards"
                  className="intro-step-estatisticas"
                >
                  Estatísticas
                </span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link menu-link${
                  location.pathname === "/perfil" && " active"
                }`}
                to="/perfil"
                role="button"
              >
                <i className="mdi mdi-face-man-profile"></i>{" "}
                <span data-key="t-dashboards" className="intro-step-perfil">
                  Perfil
                </span>
              </Link>
            </li>
            <li>
              <hr style={{ borderColor: "white" }} />
            </li>
            <li className="nav-item">
              <Link className="nav-link menu-link" to="/logout" role="button">
                <i className="mdi mdi-exit-run"></i>{" "}
                <span data-key="t-dashboards" className="intro-step-sair">
                  Sair
                </span>
              </Link>
            </li>
          </ul>
        </div>
        <div className="sidebar-background"></div>
      </div>
      <div className="vertical-overlay" onClick={handleMenuMobile}></div>
      <div className="main-content">
        <div className="page-content">{children}</div>

        <footer className="footer">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                1999 - {new Date().getFullYear()} -{" "}
                <a
                  href="https://computerid.com.br/"
                  rel="noreferrer"
                  target={"_blank"}
                >
                  Computer ID
                </a>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
}
