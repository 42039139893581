import { LayoutDefault } from "@components/layout/Default";
import { useSelector } from "react-redux";
import { getUser } from "@store/slices/userSlice";
import { useEffect, useState } from "react";
import {
  getUsability,
  searchWorstIds,
} from "@services/statistics";
import { Grid, _ } from "gridjs-react";
import { ptBR } from "gridjs/l10n";
import { Alert } from "@components/atomic/Alert/Alert";

export default function Statistics() {
  const { user } = useSelector(getUser);
  const [usabilityVer, setUsabilityVer] = useState([]);
  const [usabilityCad, setUsabilityCad] = useState([]);
  const [worstIds, setWorstIds] = useState([]);

  useEffect(() => {
    const getUsabilityVer = async () => {
      try {
        const { data: usabilityVerLocal } =
          await getUsability('ver', user.token);
          setUsabilityVer(usabilityVerLocal);
      } catch (e) {
        console.log("Erro ao obter registros", e);
      }
    };

    const getUsabilityCad = async () => {
      try {
        const { data: usabilityCadLocal } =
          await getUsability('cad', user.token);
          setUsabilityCad(usabilityCadLocal);
      } catch (e) {
        console.log("Erro ao obter registros", e);
      }
    };

    const getWorstIds = async () => {
      try {
        const { data: worstIdsLocal } = await searchWorstIds(user.token);
        setWorstIds(worstIdsLocal);
      } catch (e) {
        console.log("Erro ao buscar IDs problematicos", e);
      }
    };

    getUsabilityVer();
    getUsabilityCad();
    getWorstIds();
  }, []);

  return (
    <>
      <LayoutDefault>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0">Estatísticas</h4>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-header">
                  <strong>
                    Quantidade de pessoas diferentes que utilizaram o sistema, e faixa de dificuldade encontrada por ID_Pessoa (Verificações)
                  </strong>
                </div>
                <div className="card-body">
                  {usabilityVer.length === 0 && (
                    <Alert color="info">Nenhum registro localizado.</Alert>
                  )}

                  {usabilityVer.length > 0 && (
                    <Grid
                      data={usabilityVer}
                      columns={[
                        {
                          id: "day",
                          name: "Dia",
                        },
                        {
                          id: "total",
                          name: "Total ID Diferentes",
                        },
                        {
                          id: "range1",
                          name: "Faixa 1 - 0% a 5%",
                        },
                        {
                          id: "range2",
                          name: "Faixa 2 - 6% a 20%",
                        },
                        {
                          id: "range3",
                          name: "Faixa 2 - 21% a 40%",
                        },
                        ,
                        {
                          id: "range4",
                          name: "Faixa 2 - 41% a 70%",
                        },
                        {
                          id: "range5",
                          name: "Faixa 2 - 71% a 100%",
                        },
                      ]}
                      sort={true}
                      pagination={{ enabled: true, limit: 10 }}
                      language={ptBR}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-header">
                  <strong>
                    Quantidade de pessoas diferentes que utilizaram o sistema, e faixa de dificuldade encontrada por ID_Pessoa (Cadastros)
                  </strong>
                </div>
                <div className="card-body">
                  {usabilityCad.length === 0 && (
                    <Alert color="info">Nenhum registro localizado.</Alert>
                  )}

                  {usabilityCad.length > 0 && (
                    <Grid
                      data={usabilityCad}
                      columns={[
                        {
                          id: "day",
                          name: "Dia",
                        },
                        {
                          id: "total",
                          name: "Total ID Diferentes",
                        },
                        {
                          id: "range1",
                          name: "Faixa 1 - 0% a 5%",
                        },
                        {
                          id: "range2",
                          name: "Faixa 2 - 6% a 20%",
                        },
                        {
                          id: "range3",
                          name: "Faixa 2 - 21% a 40%",
                        },
                        ,
                        {
                          id: "range4",
                          name: "Faixa 2 - 41% a 70%",
                        },
                        {
                          id: "range5",
                          name: "Faixa 2 - 71% a 100%",
                        },
                      ]}
                      sort={true}
                      pagination={{ enabled: true, limit: 10 }}
                      language={ptBR}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-header">
                  <strong>
                    Id's com maior número de tentativas sem sucesso (últimos 3
                    dias)
                  </strong>
                </div>
                <div className="card-body">
                  {worstIds.length === 0 && (
                    <Alert color="info">Nenhum registro localizado.</Alert>
                  )}
                  {worstIds.length > 0 && (
                    <Grid
                      data={worstIds}
                      columns={[
                        {
                          name: "Id Pessoa",
                        },
                        {
                          name: "Operação",
                        },
                        {
                          name: "Quantidade",
                        },
                      ]}
                      sort={true}
                      pagination={{ enabled: true, limit: 10 }}
                      language={ptBR}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutDefault>
    </>
  );
}
